import jsPDF from 'jspdf'
import 'jspdf-autotable'

import logo from '../assets/bg-logo.png'

import { onValue, ref } from 'firebase/database'
import { database } from '../configs/firebase'
import { fecha, formatDate /*msToTime*/ } from './datetime'

import {
  getAreaToString,
  getSueldoPDiaToString,
  orderDays,
} from '../helpers/reportfunctions'
import {
  getComision,
  getComisionPzs,
  getPuesto,
  getPuestoById,
  getRetencionesById,
  getSueldoDiarioById,
  getWorkersByArea,
} from '../helpers/workersFunctions'
import { getPzs } from './deliveries'
import {
  checkWorkerArea,
  getComisionsLogisticWorker,
} from '../helpers/nominaFunctions'

import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { getWorkers } from './workers'

export const generateXLXSCash = (data, filename) => {
  // Validar que 'data' sea un array y que no esté vacío
  if (!Array.isArray(data) || data.length === 0) {
    console.error('No hay datos para exportar')
    return
  }

  // Verificar que todos los elementos dentro de 'data' sean objetos
  if (!data.every((item) => typeof item === 'object' && item !== null)) {
    console.error('Los datos deben ser objetos')
    return
  }

  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
  saveAs(blob, filename)
}

export const generatePDFCash = (data, initialDate, finalDate) => {
  let amountDay = 0
  let amount = []
  let amountList = []

  let transferDay = 0
  let transfer = []
  let transferList = []

  let servicesDay = 0
  let servicesAmount = []
  let servicesList = []

  data.forEach((item) => {
    const serviceItem = [
      item.fecha,
      item.ticket,
      item.tipo_pago,
      '$ ' + item.importe,
    ]

    servicesList.push(serviceItem)
    servicesAmount.push(item.importe * 1)

    if (item.tipo_pago === 'De Contado') {
      amount.push(serviceItem)
      amountList.push(item.importe * 1)
    } else if (
      item.tipo_pago === 'Con Tarjeta' ||
      item.tipo_pago === 'Por Transferencia'
    ) {
      transfer.push(serviceItem)
      transferList.push(item.importe * 1)
    }
  })

  servicesDay = servicesAmount.reduce((x, y) => x + y, 0)
  transferDay = transferList.reduce((x, y) => x + y, 0)
  amountDay = amountList.reduce((x, y) => x + y, 0)

  var doc = new jsPDF('p', 'pt', 'letter')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(
    195,
    65,
    `CORTE DE CAJA DE ${new Date(
      initialDate
    ).toLocaleDateString()} AL ${new Date(finalDate).toLocaleDateString()}`
  )
  doc.text(165, 75, '________________________________________________')

  doc.text(225, 110, 'Pagos Realizados')
  doc.text(250, 135, 'Total: $ ' + servicesDay)
  doc.autoTable({
    head: [['Fecha', 'Ticket', 'Tipo de Pago', 'Importe']],
    body: servicesList,
    margin: { top: 145 },
    theme: 'grid',
    styles: {
      fontSize: 10,
    },
  })

  doc.addPage('letter', 'p')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(165, 65, 'CORTE DE CAJA SERVICIOS A DOMICILIO DEL ' + fecha())
  doc.text(165, 75, '________________________________________________')
  doc.text(225, 110, 'Ticket Pagados de Contado')
  doc.text(250, 135, 'Total: $ ' + amountDay)
  doc.autoTable({
    head: [['Fecha', 'Ticket', 'Tipo de Pago', 'Importe']],
    body: amount,
    margin: { top: 145 },
    theme: 'grid',
    styles: {
      fontSize: 10,
    },
  })

  doc.addPage('letter', 'p')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(165, 65, 'CORTE DE CAJA SERVICIOS A DOMICILIO DEL ' + fecha())
  doc.text(165, 75, '________________________________________________')
  doc.text(225, 110, 'Ticket Pagados por Transferencia o Tarjeta')
  doc.text(250, 135, 'Total: $ ' + transferDay)
  doc.autoTable({
    head: [['Fecha', 'Ticket', 'Tipo de Pago', 'Importe']],
    body: transfer,
    margin: { top: 145 },
    theme: 'grid',
    styles: {
      fontSize: 10,
    },
  })

  doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: `CORTE DE CAJA DE ${new Date(
      initialDate
    ).toLocaleDateString()} AL ${new Date(finalDate).toLocaleDateString()}`,
  })
}

export const generateTicket = (print) => {
  var doc = new jsPDF('p', 'pt', [228, 3508])

  doc.addImage(logo, 'WEBP', 50, 30, 125, 50)
  doc.setFontSize(8)
  doc.text(55, 95, 'Carr. Ciudad Hidalgo-Maravatío')
  doc.text(62.5, 105, 'Ciudad Hidalgo, Michoacán')
  doc.text(70, 115, 'Tel. 01(786)-168-01-64')
  doc.text(
    20,
    125,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 135, 'Folio')
  doc.text(print.folio + '', 205, 135, { align: 'right' })
  doc.text(20, 145, 'Fecha')
  doc.text(205, 145, print.fecha, { align: 'right' })
  doc.text(20, 155, 'Hora')
  doc.text(205, 155, print.hora, { align: 'right' })
  doc.text(
    20,
    165,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 175, 'Nombre:')
  doc.text(205, 175, print.cliente, { align: 'right' })
  //doc.text(20, 185, 'Lugar de Entrega:')
  doc.text(20, 185, 'Lugar de Entrega: ' + print.lugar, {
    maxWidth: '185',
    align: 'justify',
  })
  doc.text(20, 205, 'Referencia:   ' + print.referencia, {
    maxWidth: '185',
    align: 'justify',
  })
  doc.text(
    20,
    255,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 265, 'Descripción')
  doc.text(205, 265, 'Servicio a Domicilio', { align: 'right' })
  doc.text(20, 275, 'Importe')
  doc.text(205, 275, '$ ' + print.importe, { align: 'right' })
  doc.text(
    20,
    285,
    '----------------------------------------------------------------------'
  )
  doc.text(20, 295, 'Total a Pagar')
  doc.text(205, 295, '$ ' + print.importe, { align: 'right' })
  doc.text(70, 320, '¡Gracias por su compra!')

  doc.autoPrint()
  doc.output('dataurlnewwindow', { filename: 'Ticket ' + print.folio + '.pdf' })
}

export const statusReportService = (date1, date2, unidad) => {
  let tickets = 0
  let costos = 0
  let combustible = 0
  let comisionChofer = 0
  let comisionAyudante = 0
  let sueldoChofer = 0
  let sueldoAyudante = 0
  let imss = 0
  let infonavit = 0
  let mantenimiento = 0
  let neumaticos = 0

  const limit = date2
  limit.setDate(limit.getDate() + 1)

  const equivalencias = {
    Kenworth: 'Kenworth 18',
    'Kenworth 18': 'Kenworth',
  }

  const unidadEquivalente = equivalencias[unidad] || unidad

  onValue(ref(database, 'fletes/'), (snapshot) => {
    snapshot.forEach((item) => {
      if (item.val().horaSistema >= date1 && item.val().horaSistema <= limit) {
        if (
          item.val().unidad1 === unidadEquivalente ||
          item.val().unidad2 === unidadEquivalente ||
          item.val().unidad3 === unidadEquivalente ||
          item.val().unidad1 === unidad ||
          item.val().unidad2 === unidad ||
          item.val().unidad3 === unidad
        ) {
          tickets = tickets + item.val().importe * 1
          combustible = combustible + item.val().cCombustible
          sueldoChofer = sueldoChofer + item.val().cHhChofer
          sueldoAyudante = sueldoAyudante + item.val().cHhAyudante
          imss = imss + item.val().cImss
          infonavit = infonavit + item.val().cInfonavit
          mantenimiento = mantenimiento + item.val().cMantenimiento
          neumaticos = neumaticos + item.val().cNeumaticos
          comisionChofer = comisionChofer + item.val().cComision
        } else if (unidad === 'Todas las Unidades') {
          tickets = tickets + item.val().importe * 1
          combustible = combustible + item.val().cCombustible
          sueldoChofer = sueldoChofer + item.val().cHhChofer
          sueldoAyudante = sueldoAyudante + item.val().cHhAyudante
          imss = imss + item.val().cImss
          infonavit = infonavit + item.val().cInfonavit
          mantenimiento = mantenimiento + item.val().cMantenimiento
          neumaticos = neumaticos + item.val().cNeumaticos
          comisionChofer = comisionChofer + item.val().cComision
        }
      }
    })
  })

  costos =
    combustible +
    sueldoChofer +
    sueldoAyudante +
    imss +
    infonavit +
    mantenimiento +
    neumaticos +
    comisionChofer +
    comisionAyudante

  let utilidad = tickets - costos

  let doc = new jsPDF('p', 'pt', 'letter')

  doc.addImage(logo, 'PNG', 30, 30, 125, 50)
  doc.text(235, 45, 'MATERIALES PRECONSA SA DE CV')
  doc.text(
    165,
    65,
    'ESTADO DE RESULTADOS DEL ' + formatDate(date1) + ' AL ' + formatDate(date2)
  )
  doc.text(285, 85, 'EN BASE A ' + unidad)
  doc.text(
    30,
    95,
    '________________________________________________________________'
  )

  doc.text(50, 130, '• Ingresos')
  doc.text(500, 130, `$ ${tickets}`)
  doc.text(50, 160, '• Gastos')
  doc.text(80, 180, '• Combustible')
  doc.text(430, 180, `$ ${combustible}`)
  doc.text(80, 200, '• Neumaticos')
  doc.text(430, 200, `$ ${neumaticos}`)
  doc.text(80, 220, '• Mantenimiento')
  doc.text(430, 220, `$ ${mantenimiento}`)
  doc.text(80, 240, '• Sueldo Chofer')
  doc.text(430, 240, `$ ${sueldoChofer}`)
  doc.text(80, 260, '• Sueldo Ayudante')
  doc.text(430, 260, `$ ${sueldoAyudante}`)
  doc.text(80, 280, '• Comisiones')
  doc.text(430, 280, `$ ${comisionChofer}`)
  doc.text(80, 300, '• IMSS')
  doc.text(430, 300, `$ ${imss}`)
  doc.text(80, 320, '• Infonavit')
  doc.text(430, 320, `$ ${infonavit}`)
  doc.text(50, 350, '• Total Gastos')
  doc.text(500, 350, `$ ${costos}`)
  doc.text(50, 370, '• Utilidad')
  doc.text(500, 370, `$ ${utilidad}`)

  //doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: 'Estado de Resultados ' + unidad + '.pdf',
  })
}

export const driverReport = (info) => {
  let doc = new jsPDF('p', 'pt', [228, 3508])

  doc.addImage(logo, 'PNG', 50, 30, 125, 50)
  doc.setFontSize(12)
  doc.text(35, 95, 'Reporte de Pedido de Chofer')

  doc.setFontSize(8)
  doc.text(
    20,
    105,
    '----------------------------------------------------------------------'
  )
  doc.text(25, 115, 'Fecha')
  doc.text(205, 115, info.fecha, { align: 'right' })
  doc.text(25, 125, 'Cliente')
  doc.text(205, 125, info.cliente, { align: 'right' })
  doc.text(25, 135, 'Producto')
  doc.text(205, 135, info.producto, { align: 'right' })
  doc.text(25, 145, 'Lugar')
  doc.text(205, 145, info.lugar, { align: 'right' })
  doc.text(25, 155, 'Personal')
  doc.text(205, 155, `${info.chofer}, ${info.ayudante}, ${info.ayudante2}`, {
    align: 'right',
  })
  doc.text(25, 165, 'Hora de Inicio de Carga')
  doc.text(205, 165, info.hrInicio, { align: 'right' })
  doc.text(25, 185, 'Hora de Salida de Planta')
  doc.text(205, 185, '_____________', { align: 'right' })
  doc.text(25, 205, 'Hora de Llegada a Planta')
  doc.text(205, 205, '_____________', { align: 'right' })
  doc.text(25, 225, 'Hora Estimada de Regreso')
  doc.text(205, 225, '_____________', { align: 'right' })
  doc.text(
    20,
    235,
    '----------------------------------------------------------------------'
  )

  doc.output('dataurlnewwindow', { filename: 'Reporte ' + info.date + '.pdf' })
}

export const productionReport = (object, area) => {
  const workers = getWorkersByArea(area)
  const dias = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']

  let doc = new jsPDF('p', 'pt', 'letter')

  const addHeader = () => {
    doc.setFontSize(14)
    doc.addImage(logo, 'PNG', 30, 30, 125, 50)
    doc.text(245, 45, 'MATERIALES PRECONSA SA DE CV')

    doc.text(285, 65, `REGISTRO DE NÓMINA`)
    doc.line(165, 75, 570, 75)
  }

  let subtotal = 0
  let totalNomina = 0

  workers.forEach((item) => {
    subtotal = 0
    addHeader()

    doc.setFontSize(12)

    doc.text(225, 110, 'Area: _____________________')
    doc.text(285, 109, getAreaToString(area))

    doc.text(50, 130, 'Trabajador: ')
    doc.line(115, 131, 570, 131)
    doc.text(115, 129, item.nombre)

    doc.text(50, 150, 'Puesto: ')
    doc.line(115, 151, 570, 151)
    doc.text(115, 149, item.puesto)

    doc.text(240, 180, 'Desglose de Sueldo')

    // Ancho máximo para dividir el texto en líneas
    const xDayPosition = 50 // Posición fija en el eje X
    let yPosition = 230 // Posición inicial en el eje Y
    const espacioEntreDias = 50 // Espacio entre cada día en la misma columna

    doc.text('Producto', xDayPosition + 140, yPosition - 20)
    doc.text('Cantidad', xDayPosition + 270, yPosition - 20)
    doc.text('Comisión', xDayPosition + 360, yPosition - 20)
    doc.text('Total', xDayPosition + 460, yPosition - 20)

    // Iteramos sobre cada día
    dias.forEach((dia) => {
      // Divide el texto del día en líneas según el ancho máximo
      const lines = doc.splitTextToSize(dia, 80)

      // Imprime cada línea del día centrada verticalmente en la misma columna
      lines.forEach((line) => {
        doc.text(`• ${line}`, xDayPosition, yPosition)
        yPosition += 10 // Incrementa la posición Y para la siguiente línea dentro del mismo día

        doc.text(xDayPosition + 10, yPosition + 10, '• Salario/Día: ___')
        doc.text(
          xDayPosition + 330,
          yPosition + 10,
          'x      $                   =   $'
        )
        doc.text(xDayPosition + 10, yPosition + 30, '• Horas/Día:')
        doc.text(
          xDayPosition + 330,
          yPosition + 30,
          'x      $                   =   $'
        )
      })

      // Espacio adicional entre cada día
      yPosition += espacioEntreDias
    })

    //Imprimir Productos del Día
    const data = orderDays(object)

    const xObjectPosition = 35 // Posición fija en el eje X para centrar
    let yObjectPosition = 260 // Posición inicial en el eje Y
    const espacioEntreObjectDias = 30 // Espacio entre cada día en la misma columna

    // Función para validar los valores
    const validarValor = (valor) => {
      return valor ? valor : '-' // Si el valor es null, undefined, o vacío, devolver "-"
    }

    // Iteramos sobre cada día en el orden correcto
    Object.keys(data).forEach((dia) => {
      const { producto, cantidad, salarioPDia, horaSalarioPDia } = data[dia]

      // Validar cada campo
      const productoValidado = validarValor(producto)
      const cantidadValidada = validarValor(cantidad)
      const salarioValidado = validarValor(salarioPDia)
      const horasValidadas = validarValor(horaSalarioPDia)

      // Solo imprimir los días con datos válidos
      if (
        productoValidado !== '-' ||
        cantidadValidada !== '-' ||
        salarioValidado !== '-' ||
        horasValidadas !== '-'
      ) {
        // Agregar el texto en el formato deseado
        doc.text(
          `${getSueldoPDiaToString(salarioPDia)}`,
          xObjectPosition + 100,
          yObjectPosition - 10
        )
        yObjectPosition += 15
        doc.text(`${producto}`, xObjectPosition + 130, yObjectPosition - 25)
        yObjectPosition += 15
        doc.text(
          `${cantidad || 0} pzs`,
          xObjectPosition + 330,
          yObjectPosition - 40,
          { align: 'right' }
        )
        doc.text(
          `${getComision(producto, item.id).toFixed(2)}`,
          xObjectPosition + 420,
          yObjectPosition - 40,
          { align: 'right' }
        )

        doc.text(
          `${data[dia].horaSalarioPDia || 0} hrs`,
          xObjectPosition + 330,
          yObjectPosition - 20,
          { align: 'right' }
        )
        doc.text(
          `${(getSueldoDiarioById(item.id) / 10).toFixed(2)}`,
          xObjectPosition + 420,
          yObjectPosition - 20,
          { align: 'right' }
        )

        data[dia].trabajadores.forEach((value) => {
          if (value && item && item.id === value.id) {
            if (data[dia].salarioPDia === '1') {
              doc.text(
                `${
                  (getComision(producto, item.id) * cantidad).toFixed(2) ||
                  '0.00'
                }`,
                xObjectPosition + 515,
                yObjectPosition - 40,
                { align: 'right' }
              )
              subtotal += parseFloat(getComision(producto, item.id) * cantidad)
              if (data[dia].horaSalarioPDia !== undefined) {
                doc.text(
                  `${
                    (
                      (getSueldoDiarioById(item.id) / 10) *
                      horaSalarioPDia
                    ).toFixed(2) || '0.00'
                  }`,
                  xObjectPosition + 515,
                  yObjectPosition - 20,
                  { align: 'right' }
                )
                subtotal +=
                  horaSalarioPDia * (getSueldoDiarioById(item.id) / 10)
              } else {
                doc.text('0.00', xObjectPosition + 515, yObjectPosition - 20, {
                  align: 'right',
                })
              }
            } else {
              doc.text(
                `${getSueldoDiarioById(item.id).toFixed(2) || '0.00'}`,
                xObjectPosition + 515,
                yObjectPosition - 40,
                { align: 'right' }
              )
              doc.text(
                `0.00
                `,
                xObjectPosition + 515,
                yObjectPosition - 20,
                { align: 'right' }
              )
              subtotal += getSueldoDiarioById(item.id)
            }
          }
        })

        // Espacio adicional entre cada día
        yObjectPosition += espacioEntreObjectDias
      }
    })

    doc.line(490, yObjectPosition - 45, 555, yObjectPosition - 45)

    totalNomina += subtotal

    doc.text(xObjectPosition + 464, yObjectPosition - 30, 'Subtotal   $', {
      align: 'right',
    })
    doc.text(
      xObjectPosition + 515,
      yObjectPosition - 30,
      `${subtotal.toFixed(2)}`,
      {
        align: 'right',
      }
    )

    doc.text(xObjectPosition + 464, yObjectPosition - 10, 'Retenciones   $', {
      align: 'right',
    })
    doc.text(
      xObjectPosition + 515,
      yObjectPosition - 10,
      `${getRetencionesById(item.id).toFixed(2)}`,
      {
        align: 'right',
      }
    )

    doc.text(
      xObjectPosition + 464,
      yObjectPosition + 10,
      'Sueldo Semanal   $',
      {
        align: 'right',
      }
    )
    doc.text(
      xObjectPosition + 515,
      yObjectPosition + 10,
      `${(subtotal + getRetencionesById(item.id)).toFixed(2)}`,
      {
        align: 'right',
      }
    )
    doc.line(490, yObjectPosition - 5, 555, yObjectPosition - 5)

    doc.addPage()
    addHeader()
  })

  const totalPages = doc.getNumberOfPages() // Obtén el número total de páginas

  if (totalPages > 1) {
    doc.deletePage(totalPages) // Elimina la última página
  }

  doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: 'Nomina Producción.pdf',
  })
}

export const paletReport = (object, area, id) => {
  const workers = getWorkersByArea(area)
  const dias = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']

  let doc = new jsPDF('p', 'pt', 'letter')

  const addHeader = () => {
    doc.setFontSize(14)
    doc.addImage(logo, 'PNG', 30, 30, 125, 50)
    doc.text(245, 45, 'MATERIALES PRECONSA SA DE CV')

    doc.text(285, 65, `REGISTRO DE NÓMINA`)
    doc.line(165, 75, 570, 75)
  }

  let pzs = 0
  let subtotal = 0
  let totalNomina = 0

  workers.forEach((item) => {
    subtotal = 0
    pzs = getPzs(item.id, id)
    addHeader()

    doc.setFontSize(11)

    doc.text(225, 100, 'Area: _____________________')
    doc.text(285, 99, getAreaToString(area))

    doc.text(50, 130, 'Trabajador: ')
    doc.line(115, 131, 570, 131)
    doc.text(115, 129, item.nombre)

    doc.text(50, 150, 'Puesto: ')
    doc.line(115, 151, 570, 151)
    doc.text(115, 149, item.puesto)

    doc.text(240, 180, 'Desglose de Sueldo')

    // Ancho máximo para dividir el texto en líneas
    const xDayPosition = 50 // Posición fija en el eje X
    let yPosition = 250 // Posición inicial en el eje Y
    const espacioEntreDias = 65 // Espacio entre cada día en la misma columna

    doc.text('Producto(s)', xDayPosition + 140, yPosition - 45)
    doc.text('Cantidad', xDayPosition + 270, yPosition - 45)
    doc.text('Comisión/', xDayPosition + 360, yPosition - 60)
    doc.text('Importe', xDayPosition + 360, yPosition - 45)
    doc.text('Total', xDayPosition + 460, yPosition - 45)

    // Iteramos sobre cada día
    dias.forEach((dia) => {
      // Divide el texto del día en líneas según el ancho máximo
      const lines = doc.splitTextToSize(dia, 80)

      // Imprime cada línea del día centrada verticalmente en la misma columna
      lines.forEach((line) => {
        doc.text(`• ${line}`, xDayPosition, yPosition - 20)
        yPosition += 10 // Incrementa la posición Y para la siguiente línea dentro del mismo día

        doc.text(
          xDayPosition + 330,
          yPosition - 20,
          'x      $                   =   $'
        )

        doc.text(xDayPosition + 10, yPosition, '• Salario/Día: ___')
        doc.text(
          xDayPosition + 330,
          yPosition,
          'x      $                   =   $'
        )
        doc.text(xDayPosition + 10, yPosition + 20, '• Horas/Día:')
        doc.text(
          xDayPosition + 330,
          yPosition + 20,
          'x      $                   =   $'
        )
      })

      // Espacio adicional entre cada día
      yPosition += espacioEntreDias
    })

    //Imprimir Productos del Día
    const data = orderDays(object)

    const xObjectPosition = 30 // Posición fija en el eje X para centrar
    let yObjectPosition = 250 // Posición inicial en el eje Y
    const espacioEntreObjectDias = 40 // Espacio entre cada día en la misma columna

    // Función para validar los valores
    const validarValor = (valor) => {
      return valor ? valor : '-' // Si el valor es null, undefined, o vacío, devolver "-"
    }

    // Iteramos sobre cada día en el orden correcto
    Object.keys(data).forEach((dia) => {
      const {
        producto1,
        producto2,
        cantidad1,
        cantidad2,
        salarioPDia,
        horaSalarioPDia,
      } = data[dia]

      // Validar cada campo
      const producto1Validado = validarValor(producto1)
      const producto2Validado = validarValor(producto2)
      const cantidad1Validada = validarValor(cantidad1)
      const cantidad2Validada = validarValor(cantidad2)
      const salarioValidado = validarValor(salarioPDia)
      const horasValidadas = validarValor(horaSalarioPDia)

      // Solo imprimir los días con datos válidos
      if (
        producto1Validado !== '-' ||
        producto2Validado !== '-' ||
        cantidad1Validada !== '-' ||
        cantidad2Validada !== '-' ||
        salarioValidado !== '-' ||
        horasValidadas !== '-'
      ) {
        doc.text(
          `${getSueldoPDiaToString(salarioPDia)}`,
          xObjectPosition + 100,
          yObjectPosition + 10
        )
        yObjectPosition += 40
        doc.text(`${producto1}`, xObjectPosition + 130, yObjectPosition - 50)
        yObjectPosition += 35
        doc.text(
          `${cantidad1 || 0} pzs`,
          xObjectPosition + 330,
          yObjectPosition - 85,
          { align: 'right' }
        )
        doc.text(
          `${getComision(producto1, item.id).toFixed(2)}`,
          xObjectPosition + 420,
          yObjectPosition - 85,
          { align: 'right' }
        )
        doc.text(`${producto2}`, xObjectPosition + 130, yObjectPosition - 65)
        doc.text(
          `${cantidad2 || 0} pzs`,
          xObjectPosition + 330,
          yObjectPosition - 65,
          { align: 'right' }
        )
        doc.text(
          `${getComision(producto2, item.id).toFixed(2)}`,
          xObjectPosition + 420,
          yObjectPosition - 65,
          { align: 'right' }
        )
        doc.text(
          `${data[dia].horaSalarioPDia || 0} hrs`,
          xObjectPosition + 330,
          yObjectPosition - 45,
          { align: 'right' }
        )
        doc.text(
          `${(getSueldoDiarioById(item.id) / 10).toFixed(2)}`,
          xObjectPosition + 420,
          yObjectPosition - 45,
          { align: 'right' }
        )

        Object.keys(data[dia].trabajadores).forEach((value) => {
          const salarioArea = checkWorkerArea(item.id, id)
          salarioArea.forEach((day) => {
            if (getPuestoById(item.id) === value && day.key === dia) {
              data[dia].trabajadores[
                value
              ].salarioGenerado = `**${day.salary.toFixed(2)}`
              data[dia].trabajadores[value].id = item.id
            }
          })

          if (
            data[dia].trabajadores[value] &&
            item &&
            item.id === data[dia].trabajadores[value].id &&
            getPuestoById(item.id) === value
          ) {
            if (data[dia].salarioPDia === '1') {
              if (salarioArea !== null) {
                //Imprime los importes de los productos 1 y 2 si el salario por dia es no
                doc.text(
                  `${(
                    data[dia].trabajadores[value].salarioGenerado -
                    data[dia].horaSalarioPDia *
                      (getSueldoDiarioById(item.id) / 10) -
                    (producto2
                      ? getComision(producto2, item.id) * cantidad2
                      : 0)
                  ).toFixed(2)}`,
                  xObjectPosition + 515,
                  yObjectPosition - 85,
                  { align: 'right' }
                )
                doc.text(
                  `${(
                    data[dia].trabajadores[value].salarioGenerado -
                    data[dia].horaSalarioPDia *
                      (getSueldoDiarioById(item.id) / 10) -
                    (producto1
                      ? getComision(producto1, item.id) * cantidad1
                      : 0)
                  ).toFixed(2)}`,
                  xObjectPosition + 515,
                  yObjectPosition - 65,
                  { align: 'right' }
                )

                //Salario
                subtotal += parseFloat(
                  data[dia].trabajadores[value].salarioGenerado.replace(
                    /\*\*/g,
                    ''
                  ) -
                    data[dia].horaSalarioPDia *
                      (getSueldoDiarioById(item.id) / 10)
                )
              } else {
                doc.text(
                  `${
                    ((getComision(producto1, item.id) * cantidad1) / 3).toFixed(
                      2
                    ) || '0.00'
                  }`,
                  xObjectPosition + 515,
                  yObjectPosition - 85,
                  { align: 'right' }
                )
                subtotal +=
                  parseFloat(getComision(producto1, item.id) * cantidad1) / 3
              }
              if (
                !data[dia].trabajadores[value].salarioGenerado.includes('**')
              ) {
                doc.text(
                  `${(
                    data[dia].horaSalarioPDia *
                    (getSueldoDiarioById(item.id) / 10)
                  ).toFixed(2)}`,
                  xObjectPosition + 515,
                  yObjectPosition - 45,
                  { align: 'right' }
                )
                subtotal +=
                  data[dia].horaSalarioPDia *
                  (getSueldoDiarioById(item.id) / 10)
              } else {
                doc.text(`0.00`, xObjectPosition + 515, yObjectPosition - 20, {
                  align: 'right',
                })
              }
            } else {
              if (salarioArea !== null) {
                doc.text(
                  `${data[dia].trabajadores[value].salarioGenerado}`,
                  xObjectPosition + 515,
                  yObjectPosition - 85,
                  { align: 'right' }
                )
                subtotal += parseFloat(
                  data[dia].trabajadores[value].salarioGenerado.replace(
                    /\*\*/g,
                    ''
                  )
                )
              } else {
                doc.text(
                  `${getSueldoDiarioById(item.id).toFixed(2)}`,
                  xObjectPosition + 515,
                  yObjectPosition - 85,
                  { align: 'right' }
                )
                subtotal += getSueldoDiarioById(item.id)
              }
              doc.text(`0.00`, xObjectPosition + 515, yObjectPosition - 65, {
                align: 'right',
              })
              doc.text(`0.00`, xObjectPosition + 515, yObjectPosition - 45, {
                align: 'right',
              })
            }
          }
        })

        // Espacio adicional entre cada día
        //yObjectPosition += espacioEntreObjectDias - 5
      }
    })

    //Información final
    doc.text(50, yPosition - 25, '• Pzs Cargadas:')
    doc.text(
      xDayPosition + 330,
      yPosition - 25,
      'x      $                   =   $'
    )
    doc.text(`${pzs} pzs`, xObjectPosition + 280, yPosition - 25)
    doc.text(
      `${getComisionPzs(item.id).toFixed(2)}`,
      xObjectPosition + 420,
      yPosition - 25,
      { align: 'right' }
    )
    doc.text(
      `${(getComisionPzs(item.id) * pzs).toFixed(2)}`,
      xObjectPosition + 515,
      yPosition - 25,
      { align: 'right' }
    )

    subtotal += getComisionPzs(item.id) * pzs

    doc.line(485, yPosition - 20, 550, yPosition - 20)

    totalNomina += subtotal

    doc.text(xObjectPosition + 464, yPosition - 10, 'Subtotal   $ ', {
      align: 'right',
    })
    doc.text(xObjectPosition + 515, yPosition - 10, `${subtotal.toFixed(2)}`, {
      align: 'right',
    })

    doc.text(xObjectPosition + 464, yPosition + 10, 'Retenciones   $ ', {
      align: 'right',
    })
    doc.text(
      xObjectPosition + 515,
      yPosition + 10,
      `${getRetencionesById(item.id).toFixed(2)}`,
      {
        align: 'right',
      }
    )

    doc.text(xObjectPosition + 464, yPosition + 30, 'Sueldo Semanal   $ ', {
      align: 'right',
    })
    doc.text(
      xObjectPosition + 515,
      yPosition + 30,
      `${(subtotal + getRetencionesById(item.id)).toFixed(2)}`,
      {
        align: 'right',
      }
    )
    doc.line(485, yPosition + 15, 550, yPosition + 15)

    doc.text(50, 750, '** Sueldo generado en otra area')

    doc.addPage()
  })

  const totalPages = doc.getNumberOfPages() // Obtén el número total de páginas

  if (totalPages > 1) {
    doc.deletePage(totalPages) // Elimina la última página
  }

  doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: 'Nomina Paletizado.pdf',
  })
}

export const logisticReport = (object) => {
  let doc = new jsPDF('p', 'pt', 'letter')

  const addHeader = () => {
    doc.setFontSize(14)
    doc.addImage(logo, 'PNG', 30, 30, 125, 50)
    doc.text(245, 45, 'MATERIALES PRECONSA SA DE CV')

    doc.text(285, 65, `REGISTRO DE NÓMINA`)
    doc.line(165, 75, 570, 75)
  }

  object.forEach((item) => {
    if (item.trabajador.nombre !== 'PALETIZADORES') {
      addHeader()

      doc.text(255, 115, 'Trabajador')
      doc.text(50, 139, 'Nombre: ' + item.trabajador.nombre)
      doc.line(106, 141, 570, 141)
      doc.text(
        50,
        159,
        'Puesto: ' + getPuesto(getPuestoById(item.trabajador.id)).toUpperCase()
      )
      doc.line(100, 161, 570, 161)

      doc.text(230, 200, 'Desglose de Sueldo')

      //Calculo días laborados
      doc.text(200, 230, 'Núm. de Días')
      doc.text(340, 230, 'Sueldo diario')
      doc.text(500, 230, 'Total')
      doc.text(50, 250, '• Días laborados: ')
      doc.text(200, 250, `${item.diasLaborados || 0} días`)
      doc.text(290, 250, 'x')
      doc.text(340, 250, '$')
      doc.text(
        420,
        250,
        `${getSueldoDiarioById(item.trabajador.id).toFixed(2) || '0.00'}`,
        {
          align: 'right',
        }
      )
      doc.text(430, 250, '=')
      doc.text(460, 250, '$')
      doc.text(
        550,
        250,
        `${(
          item.diasLaborados * getSueldoDiarioById(item.trabajador.id)
        ).toFixed(2)}`,
        { align: 'right' }
      )

      //Calculo viajes realizados
      doc.text(200, 280, 'Núm. de Viajes')
      doc.text(340, 280, 'Comisión')
      doc.text(500, 280, 'Total')
      doc.text(50, 280, '• Viajes:')
      // unidad
      doc.text(70, 300, '• Kenworth 18:')
      doc.text(200, 300, `${item.viajes.kenworth || 0} viajes`)
      doc.text(290, 300, 'x')
      doc.text(340, 300, '$')
      doc.text(
        420,
        300,
        `${(item.viajes.kenworth === 0 ||
        getPuestoById(item.trabajador.id) !== '8'
          ? 0
          : getComisionsLogisticWorker(
              item.trabajador.id,
              item.viajes.kenworth,
              'kenworth 18'
            ) / item.viajes.kenworth
        ).toFixed(2)}`,
        { align: 'right' }
      )
      doc.text(430, 300, '=')
      doc.text(460, 300, '$')
      doc.text(
        550,
        300,
        `${
          getComisionsLogisticWorker(
            item.trabajador.id,
            item.viajes.kenworth,
            'kenworth 18'
          ).toFixed(2) || '0.00'
        }`,
        { align: 'right' }
      )
      // unidad
      doc.text(70, 320, '• Kenworth 10:')
      doc.text(200, 320, `${item.viajes.kenworth10 || 0} viajes`)
      doc.text(290, 320, 'x')
      doc.text(340, 320, '$')
      doc.text(
        420,
        320,
        `${(item.viajes.kenworth10 === 0 ||
        getPuestoById(item.trabajador.id) !== '8'
          ? 0
          : getComisionsLogisticWorker(
              item.trabajador.id,
              item.viajes.kenworth10,
              'kenworth 10'
            ) / item.viajes.kenworth10
        ).toFixed(2)}`,
        { align: 'right' }
      )
      doc.text(430, 320, '=')
      doc.text(460, 320, '$')
      doc.text(
        550,
        320,
        `${
          getComisionsLogisticWorker(
            item.trabajador.id,
            item.viajes.kenworth10,
            'kenworth 10'
          ).toFixed(2) || '0.00'
        }`,
        { align: 'right' }
      )
      //unidad
      doc.text(70, 340, '• Inter/Ford:')
      doc.text(200, 340, `${item.viajes.camion || 0} viajes`)
      doc.text(290, 340, 'x')
      doc.text(340, 340, '$')
      doc.text(
        420,
        340,
        `${(item.viajes.camion === 0 ||
        getPuestoById(item.trabajador.id) !== '8'
          ? 0
          : getComisionsLogisticWorker(
              item.trabajador.id,
              item.viajes.camion,
              'camion'
            ) / item.viajes.camion
        ).toFixed(2)}`,
        { align: 'right' }
      )
      doc.text(430, 340, '=')
      doc.text(460, 340, '$')
      doc.text(
        550,
        340,
        `${
          getComisionsLogisticWorker(
            item.trabajador.id,
            item.viajes.camion,
            'camion'
          ).toFixed(2) || '0.00'
        }`,
        { align: 'right' }
      )
      //unidad
      doc.text(70, 360, '• Ford 2014:')
      doc.text(200, 360, `${item.viajes.doble || 0} viajes`)
      doc.text(290, 360, 'x')
      doc.text(340, 360, '$')
      doc.text(
        420,
        360,
        `${(item.viajes.doble === 0 || getPuestoById(item.trabajador.id) !== '8'
          ? 0
          : getComisionsLogisticWorker(
              item.trabajador.id,
              item.viajes.doble,
              'doble'
            ) / item.viajes.doble
        ).toFixed(2)}`,
        { align: 'right' }
      )
      doc.text(430, 360, '=')
      doc.text(460, 360, '$')
      doc.text(
        550,
        360,
        `${
          getComisionsLogisticWorker(
            item.trabajador.id,
            item.viajes.doble,
            'doble'
          ).toFixed(2) || '0.00'
        }`,
        { align: 'right' }
      )
      doc.text(70, 380, '• Nissan:')
      doc.text(200, 380, `${item.viajes.nissan || 0} viajes`)
      doc.text(290, 380, 'x')
      doc.text(340, 380, '$')
      doc.text(
        420,
        380,
        `${(item.viajes.nissan === 0 ||
        getPuestoById(item.trabajador.id) !== '8'
          ? 0
          : getComisionsLogisticWorker(
              item.trabajador.id,
              item.viajes.nissan,
              'nissan'
            ) / item.viajes.nissan
        ).toFixed(2)}`,
        { align: 'right' }
      )
      doc.text(430, 380, '=')
      doc.text(460, 380, '$')
      doc.text(
        550,
        380,
        `${
          getComisionsLogisticWorker(
            item.trabajador.id,
            item.viajes.nissan,
            'nissan'
          ).toFixed(2) || '0.00'
        }`,
        { align: 'right' }
      )

      doc.text(550, 381, `__________`, { align: 'right' })
      doc.text(460, 400, '$')
      doc.text(
        550,
        400,
        `${(
          getComisionsLogisticWorker(
            item.trabajador.id,
            item.viajes.kenworth,
            'kenworth 18'
          ) *
            1 +
          getComisionsLogisticWorker(
            item.trabajador.id,
            item.viajes.kenworth,
            'kenworth 10'
          ) *
            1 +
          getComisionsLogisticWorker(
            item.trabajador.id,
            item.viajes.camion,
            'camion'
          ) *
            1 +
          getComisionsLogisticWorker(
            item.trabajador.id,
            item.viajes.doble,
            'doble'
          ) *
            1 +
          getComisionsLogisticWorker(
            item.trabajador.id,
            item.viajes.nissan,
            'nissan'
          ) *
            1
        ).toFixed(2)}`,
        { align: 'right' }
      )

      //Calculo de piezas cargadas en fabrica
      doc.text(200, 430, 'Núm. de Piezas')
      doc.text(340, 430, 'Monto/Pieza')
      doc.text(500, 430, 'Total')
      doc.text(50, 450, '• Piezas Cargadas:')
      doc.text(200, 450, `${item.pzsCargadas || 0} pzs`)
      doc.text(290, 450, 'x')
      doc.text(340, 450, '$')
      doc.text(420, 450, `${getComisionPzs(item.trabajador.id)}`, {
        align: 'right',
      })
      doc.text(430, 450, '=')
      doc.text(460, 450, '$')
      doc.text(
        550,
        450,
        `${(item.pzsCargadas * getComisionPzs(item.trabajador.id)).toFixed(2)}`,
        { align: 'right' }
      )

      //Calculo de bonos
      doc.text(500, 480, 'Total')
      doc.text(50, 480, '• Bonos:')

      let yPosition = 500

      if (Array.isArray(item.bonos)) {
        item.bonos.forEach((bono) => {
          doc.text(70, yPosition, bono)
          doc.text(
            550,
            yPosition,
            `${getComisionsLogisticWorker(
              item.trabajador.id,
              1,
              bono.toLowerCase()
            ).toFixed(2)}`,
            { align: 'right' }
          )
          doc.text(460, yPosition, '$')
          doc.text(430, yPosition, '=')
          yPosition += 20
        })
      } else {
        console.warn('item.bonos no es un array:', item.bonos)
      }

      //Calculo Horas extra
      doc.text(200, yPosition + 10, 'Núm. de Horas')
      doc.text(340, yPosition + 10, 'Sueldo/Hora')
      doc.text(500, yPosition + 10, 'Total')
      doc.text(50, yPosition + 30, '• Horas Extra')
      doc.text(200, yPosition + 30, `${item.horasExtra || 0} horas`)
      doc.text(290, yPosition + 30, 'x')
      doc.text(340, yPosition + 30, '$')
      doc.text(
        420,
        yPosition + 30,
        (getSueldoDiarioById(item.trabajador.id) / 10).toFixed(2),
        { align: 'right' }
      )
      doc.text(460, yPosition + 30, '$')
      doc.text(430, yPosition + 30, '=')
      doc.text(
        550,
        yPosition + 30,
        `${
          (
            item.horasExtra *
            (getSueldoDiarioById(item.trabajador.id) / 10)
          ).toFixed(2) || '0.00'
        }`,
        { align: 'right' }
      )

      //Calculo de totales y retenciones
      doc.text(400, yPosition + 60, 'Subtotal', { align: 'right' })
      doc.text(430, yPosition + 60, '=')
      doc.text(460, yPosition + 60, '$')
      doc.text(
        550,
        yPosition + 60,
        `${parseFloat(item.salarioSemanal).toFixed(2) || '0.00'}`,
        {
          align: 'right',
        }
      )
      doc.text(400, yPosition + 80, 'Retenciones', { align: 'right' })
      doc.text(430, yPosition + 80, '=')
      doc.text(460, yPosition + 80, '$')
      doc.text(
        550,
        yPosition + 80,
        `${getRetencionesById(item.trabajador.id) || '0.00'}`,
        {
          align: 'right',
        }
      )
      doc.text(550, yPosition + 81, `__________`, { align: 'right' })
      doc.text(400, yPosition + 100, 'Sueldo semanal', { align: 'right' })
      doc.text(430, yPosition + 100, '=')
      doc.text(460, yPosition + 100, '$')
      doc.text(
        550,
        yPosition + 100,
        `${parseFloat(item.salarioNeto).toFixed(2) || '0.00'}`,
        {
          align: 'right',
        }
      )
      //doc.text(50, 750, '* Camiones Inter, Ford y Kenworth 10')

      doc.addPage()
    }
  })

  doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: 'Nomina Logística.pdf',
  })
}

export const salesReport = (object) => {
  let doc = new jsPDF('p', 'pt', 'letter')

  const addHeader = () => {
    doc.setFontSize(14)
    doc.addImage(logo, 'PNG', 30, 30, 125, 50)
    doc.text(245, 45, 'MATERIALES PRECONSA SA DE CV')

    doc.text(285, 65, `REGISTRO DE NÓMINA`)
    doc.line(165, 75, 570, 75)
  }

  Object.values(object.vendedores).map((item) => {
    addHeader()
    doc.text(255, 115, 'Trabajador')
    doc.text(50, 139, 'Nombre: ' + item.trabajador.nombre)
    doc.line(106, 141, 570, 141)
    doc.text(
      50,
      159,
      'Puesto: ' + getPuesto(getPuestoById(item.trabajador.id)).toUpperCase()
    )
    doc.line(100, 161, 570, 161)

    doc.text(230, 200, 'Desglose de Sueldo')

    //Calculo días laborados
    doc.text(200, 230, 'Núm. de Días')
    doc.text(340, 230, 'Sueldo diario')
    doc.text(500, 230, 'Total')
    doc.text(50, 250, '• Días laborados: ')
    doc.text(200, 250, `${item.diasLaborados || 0} días`)
    doc.text(290, 250, 'x')
    doc.text(340, 250, '$')
    doc.text(
      420,
      250,
      `${getSueldoDiarioById(item.trabajador.id).toFixed(2) || '0.00'}`,
      {
        align: 'right',
      }
    )
    doc.text(430, 250, '=')
    doc.text(460, 250, '$')
    doc.text(
      550,
      250,
      `${(item.diasLaborados * getSueldoDiarioById(item.trabajador.id)).toFixed(
        2
      )}`,
      { align: 'right' }
    )

    //Calculo viajes realizados
    doc.text(200, 280, 'Cant. Vendida')
    doc.text(340, 280, 'Comisión')
    doc.text(500, 280, 'Total')
    doc.text(50, 280, '• Ventas:')
    doc.text(70, 300, '• CPC30R:')
    doc.text(200, 300, `${object.comisiones.cpc30r || 0} piezas`)
    doc.text(290, 300, 'x')
    doc.text(340, 300, '$')
    doc.text(
      420,
      300,
      `${(object.comisiones.cpc30r > 0
        ? getComisionsLogisticWorker(
            item.trabajador.id,
            object.comisiones.cpc30r,
            'cpc30r'
          ) / object.comisiones.cpc30r
        : 0
      ).toFixed(2)}`,
      { align: 'right' }
    )
    doc.text(430, 300, '=')
    doc.text(460, 300, '$')
    doc.text(
      550,
      300,
      `${
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.cpc30r,
          'cpc30r'
        ).toFixed(2) || '0.00'
      }`,
      { align: 'right' }
    )
    doc.text(70, 320, '• MORT:')
    doc.text(200, 320, `${object.comisiones.mort || 0} piezas`)
    doc.text(290, 320, 'x')
    doc.text(340, 320, '$')
    doc.text(
      420,
      320,
      `${(object.comisiones.mort > 0
        ? getComisionsLogisticWorker(
            item.trabajador.id,
            object.comisiones.mort,
            'mort'
          ) / object.comisiones.mort
        : 0
      ).toFixed(2)}`,
      { align: 'right' }
    )
    doc.text(430, 320, '=')
    doc.text(460, 320, '$')
    doc.text(
      550,
      320,
      `${
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.mort,
          'mort'
        ).toFixed(2) || '0.00'
      }`,
      { align: 'right' }
    )
    doc.text(70, 340, '• VAR3/8:')
    doc.text(200, 340, `${object.comisiones.var38 || 0} piezas`)
    doc.text(290, 340, 'x')
    doc.text(340, 340, '$')
    doc.text(
      420,
      340,
      `${(object.comisiones.var38 > 0
        ? getComisionsLogisticWorker(
            item.trabajador.id,
            object.comisiones.var38,
            'var3/8'
          ) / object.comisiones.var38
        : 0
      ).toFixed(2)}`,
      { align: 'right' }
    )
    doc.text(430, 340, '=')
    doc.text(460, 340, '$')
    doc.text(
      550,
      340,
      `${
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.var38,
          'var3/8'
        ).toFixed(2) || '0.00'
      }`,
      { align: 'right' }
    )
    doc.text(70, 360, '• Block:')
    doc.text(200, 360, `${object.comisiones.block || 0} piezas`)
    doc.text(290, 360, 'x')
    doc.text(340, 360, '$')
    doc.text(
      420,
      360,
      `${(object.comisiones.block > 0
        ? getComisionsLogisticWorker(
            item.trabajador.id,
            object.comisiones.block,
            'block'
          ) / object.comisiones.block
        : 0
      ).toFixed(2)}`,
      { align: 'right' }
    )
    doc.text(430, 360, '=')
    doc.text(460, 360, '$')
    doc.text(
      550,
      360,
      `${
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.block,
          'block'
        ).toFixed(2) || '0.00'
      }`,
      { align: 'right' }
    )
    doc.text(70, 380, '• Tabicon:')
    doc.text(200, 380, `${object.comisiones.tabicon || 0} piezas`)
    doc.text(290, 380, 'x')
    doc.text(340, 380, '$')
    doc.text(
      420,
      380,
      `${(object.comisiones.tabicon > 0
        ? getComisionsLogisticWorker(
            item.trabajador.id,
            object.comisiones.tabicon,
            'tabicon'
          ) / object.comisiones.tabicon
        : 0
      ).toFixed(2)}`,
      { align: 'right' }
    )
    doc.text(430, 380, '=')
    doc.text(460, 380, '$')
    doc.text(
      550,
      380,
      `${
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.tabicon,
          'tabicon'
        ).toFixed(2) || '0.00'
      }`,
      { align: 'right' }
    )
    doc.text(70, 400, '• Poste:')
    doc.text(200, 400, `${object.comisiones.poste || 0} piezas`)
    doc.text(290, 400, 'x')
    doc.text(340, 400, '$')
    doc.text(
      420,
      400,
      `${(object.comisiones.poste > 0
        ? getComisionsLogisticWorker(
            item.trabajador.id,
            object.comisiones.poste,
            'poste'
          ) / object.comisiones.poste
        : 0
      ).toFixed(2)}`,
      { align: 'right' }
    )
    doc.text(430, 400, '=')
    doc.text(460, 400, '$')
    doc.text(
      550,
      400,
      `${
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.poste,
          'poste'
        ).toFixed(2) || '0.00'
      }`,
      { align: 'right' }
    )

    doc.text(550, 401, `__________`, { align: 'right' })
    doc.text(460, 420, '$')

    doc.text(
      550,
      420,
      `${(
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.cpc30r,
          'cpc30r'
        ) *
          1 +
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.mort,
          'mort'
        ) *
          1 +
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.var38,
          'var3/8'
        ) *
          1 +
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.block,
          'block'
        ) *
          1 +
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.tabicon,
          'tabicon'
        ) *
          1 +
        getComisionsLogisticWorker(
          item.trabajador.id,
          object.comisiones.poste,
          'poste'
        ) *
          1
      ).toFixed(2)}`,
      { align: 'right' }
    )

    //Calculo de bonos
    doc.text(500, 450, 'Total')
    doc.text(50, 450, '• Bonos:')

    let yPosition = 470

    if (Array.isArray(item.bonos)) {
      item.bonos.forEach((bono) => {
        doc.text(70, yPosition, bono)
        doc.text(
          550,
          yPosition,
          `${getComisionsLogisticWorker(
            item.trabajador.id,
            1,
            bono.toLowerCase()
          ).toFixed(2)}`,
          { align: 'right' }
        )
        doc.text(460, yPosition, '$')
        doc.text(430, yPosition, '=')
        yPosition += 20
      })
    } else {
      console.warn('item.bonos no es un array:', item.bonos)
    }

    //Calculo Horas extra
    doc.text(200, yPosition + 10, 'Núm. de Horas')
    doc.text(340, yPosition + 10, 'Sueldo/Hora')
    doc.text(500, yPosition + 10, 'Total')
    doc.text(50, yPosition + 30, '• Horas Extra')
    doc.text(200, yPosition + 30, `${item.horasExtra || 0} horas`)
    doc.text(290, yPosition + 30, 'x')
    doc.text(340, yPosition + 30, '$')
    doc.text(
      420,
      yPosition + 30,
      (getSueldoDiarioById(item.trabajador.id) / 10).toFixed(2),
      { align: 'right' }
    )
    doc.text(460, yPosition + 30, '$')
    doc.text(430, yPosition + 30, '=')
    doc.text(
      550,
      yPosition + 30,
      `${
        (
          item.horasExtra *
          (getSueldoDiarioById(item.trabajador.id) / 10)
        ).toFixed(2) || '0.00'
      }`,
      { align: 'right' }
    )

    //Calculo de totales y retenciones
    doc.text(400, yPosition + 60, 'Subtotal', { align: 'right' })
    doc.text(430, yPosition + 60, '=')
    doc.text(460, yPosition + 60, '$')
    doc.text(
      550,
      yPosition + 60,
      `${parseFloat(item.salarioSemanal).toFixed(2) || '0.00'}`,
      {
        align: 'right',
      }
    )
    doc.text(400, yPosition + 80, 'Retenciones', { align: 'right' })
    doc.text(430, yPosition + 80, '=')
    doc.text(460, yPosition + 80, '$')
    doc.text(
      550,
      yPosition + 80,
      `${getRetencionesById(item.trabajador.id) || '0.00'}`,
      {
        align: 'right',
      }
    )
    doc.text(550, yPosition + 81, `__________`, { align: 'right' })
    doc.text(400, yPosition + 100, 'Sueldo semanal', { align: 'right' })
    doc.text(430, yPosition + 100, '=')
    doc.text(460, yPosition + 100, '$')
    doc.text(
      550,
      yPosition + 100,
      `${parseFloat(item.salarioNeto).toFixed(2) || '0.00'}`,
      {
        align: 'right',
      }
    )

    doc.addPage()
  })

  const totalPages = doc.getNumberOfPages() // Obtén el número total de páginas

  if (totalPages > 1) {
    doc.deletePage(totalPages) // Elimina la última página
  }

  doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: 'Nomina Ventas.pdf',
  })
}

export const adminReport = (object) => {
  let doc = new jsPDF('p', 'pt', 'letter')

  const addHeader = () => {
    doc.setFontSize(14)
    doc.addImage(logo, 'PNG', 30, 30, 125, 50)
    doc.text(245, 45, 'MATERIALES PRECONSA SA DE CV')

    doc.text(285, 65, `REGISTRO DE NÓMINA`)
    doc.line(165, 75, 570, 75)
  }

  object.map((item) => {
    addHeader()
    doc.text(255, 115, 'Trabajador')
    doc.text(50, 139, 'Nombre: ' + item.trabajador.nombre)
    doc.line(106, 141, 570, 141)
    doc.text(
      50,
      159,
      'Puesto: ' + getPuesto(getPuestoById(item.trabajador.id)).toUpperCase()
    )
    doc.line(100, 161, 570, 161)

    doc.text(230, 200, 'Desglose de Sueldo')

    //Calculo días laborados
    doc.text(200, 230, 'Núm. de Días')
    doc.text(340, 230, 'Sueldo diario')
    doc.text(500, 230, 'Total')
    doc.text(50, 250, '• Días laborados: ')
    doc.text(200, 250, `${item.diasLaborados || 0} días`)
    doc.text(290, 250, 'x')
    doc.text(340, 250, '$')
    doc.text(
      420,
      250,
      `${getSueldoDiarioById(item.trabajador.id).toFixed(2) || '0.00'}`,
      {
        align: 'right',
      }
    )
    doc.text(430, 250, '=')
    doc.text(460, 250, '$')
    doc.text(
      550,
      250,
      `${(item.diasLaborados * getSueldoDiarioById(item.trabajador.id)).toFixed(
        2
      )}`,
      { align: 'right' }
    )

    //Calculo de bonos
    doc.text(500, 280, 'Total')
    doc.text(50, 280, '• Bonos:')

    let yPosition = 300

    if (Array.isArray(item.bonos)) {
      item.bonos.forEach((bono) => {
        doc.text(70, yPosition, bono)
        doc.text(
          550,
          yPosition,
          `${getComisionsLogisticWorker(
            item.trabajador.id,
            1,
            bono.toLowerCase()
          ).toFixed(2)}`,
          { align: 'right' }
        )
        doc.text(460, yPosition, '$')
        doc.text(430, yPosition, '=')
        yPosition += 20
      })
    } else {
      console.warn('item.bonos no es un array:', item.bonos)
    }

    //Calculo Horas extra
    doc.text(200, yPosition + 10, 'Núm. de Horas')
    doc.text(340, yPosition + 10, 'Sueldo/Hora')
    doc.text(500, yPosition + 10, 'Total')
    doc.text(50, yPosition + 30, '• Horas Extra')
    doc.text(200, yPosition + 30, `${item.horasExtra || 0} horas`)
    doc.text(290, yPosition + 30, 'x')
    doc.text(340, yPosition + 30, '$')
    doc.text(
      420,
      yPosition + 30,
      (getSueldoDiarioById(item.trabajador.id) / 10).toFixed(2),
      { align: 'right' }
    )
    doc.text(460, yPosition + 30, '$')
    doc.text(430, yPosition + 30, '=')
    doc.text(
      550,
      yPosition + 30,
      `${
        (
          item.horasExtra *
          (getSueldoDiarioById(item.trabajador.id) / 10)
        ).toFixed(2) || '0.00'
      }`,
      { align: 'right' }
    )

    //Calculo de totales y retenciones
    doc.text(400, yPosition + 60, 'Subtotal', { align: 'right' })
    doc.text(430, yPosition + 60, '=')
    doc.text(460, yPosition + 60, '$')
    doc.text(
      550,
      yPosition + 60,
      `${parseFloat(item.salarioSemanal).toFixed(2) || '0.00'}`,
      {
        align: 'right',
      }
    )
    doc.text(400, yPosition + 80, 'Retenciones', { align: 'right' })
    doc.text(430, yPosition + 80, '=')
    doc.text(460, yPosition + 80, '$')
    doc.text(
      550,
      yPosition + 80,
      `${getRetencionesById(item.trabajador.id) || '0.00'}`,
      {
        align: 'right',
      }
    )
    doc.text(550, yPosition + 81, `__________`, { align: 'right' })
    doc.text(400, yPosition + 100, 'Sueldo semanal', { align: 'right' })
    doc.text(430, yPosition + 100, '=')
    doc.text(460, yPosition + 100, '$')
    doc.text(
      550,
      yPosition + 100,
      `${parseFloat(item.salarioNeto).toFixed(2) || '0.00'}`,
      {
        align: 'right',
      }
    )

    doc.addPage()
  })

  const totalPages = doc.getNumberOfPages() // Obtén el número total de páginas

  if (totalPages > 1) {
    doc.deletePage(totalPages) // Elimina la última página
  }

  doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: 'Nomina Administración.pdf',
  })
}

const buscarSalarioNeto = (entries, workerId) => {
  // Recorre las entradas y busca el salario neto para el trabajador con el ID dado
  for (const entry of entries) {
    if (entry.trabajador && entry.trabajador.id === workerId) {
      return entry.salarioNeto
    }
  }
  return null // Si no encuentra el trabajador
}

const calcularSalarioTotalEnDias = (area, workerId) => {
  let salarioTotal = 0

  // Iteramos sobre los días de la semana en el área
  for (const dia of Object.values(area)) {
    if (dia.trabajadores) {
      // Iteramos sobre los trabajadores en cada día
      for (const trabajador of Object.values(dia.trabajadores)) {
        if (trabajador.id === workerId) {
          // Acumulamos el salario generado
          salarioTotal += parseFloat(trabajador.salarioGenerado || 0)
        }
      }
    }
  }
  return salarioTotal // Devolvemos el salario total
}

export const generateMonthlyReport = (data, mes) => {
  const workers = getWorkers()

  const semanas = []

  let tAdmin = 0,
    tVentas = 0,
    tDistrib = 0,
    tFabr = 0
  let tAdminJO = 0,
    tVentasJO = 0
  let counter = 1

  let doc = new jsPDF('p', 'pt', 'letter')

  const addHeader = () => {
    doc.setFontSize(14)
    doc.addImage(logo, 'PNG', 30, 30, 125, 50)
    doc.text(245, 45, 'MATERIALES PRECONSA SA DE CV')

    doc.text(255, 65, `REPORTE MENSUAL DE NÓMINA`)
    doc.line(165, 75, 570, 75)
  }

  data.forEach((week) => {
    if (week.status !== '3') {
      addHeader()

      let xPosition = 60,
        yPosition = 140

      const semana = {
        adminPr: 0,
        ventasPr: 0,
        distribPr: 0,
        fabrPr: 0,
        adminJOW: 0,
        ventasJOW: 0,
      }

      doc.text(
        xPosition + 130,
        yPosition - 40,
        `Semana del ${new Date(
          week.initialDate
        ).toLocaleDateString()} al ${new Date(
          week.finalDate
        ).toLocaleDateString()}`
      )

      doc.text(xPosition + 70, yPosition - 20, 'Trabajadores')
      doc.text(xPosition + 290, yPosition - 20, 'Sueldo Jóse Odilón', {
        align: 'right',
      })
      doc.text(xPosition + 410, yPosition - 20, 'Sueldo Preconsa', {
        align: 'right',
      })
      doc.text(xPosition + 500, yPosition - 20, 'Sueldo Neto', {
        align: 'right',
      })

      workers.forEach((worker) => {
        if (
          worker.nombre.toLowerCase() !== 'por definir' &&
          worker.nombre.toLowerCase() !== 'sin ayudante'
        ) {
          doc.setFontSize('11')
          doc.text(xPosition + 150, yPosition, worker.nombre, {
            align: 'right',
          })

          if (
            String(worker.area) === '3' ||
            String(worker.area) === '4' ||
            String(worker.area) === '5' ||
            String(worker.area) === '6'
          ) {
            let areaKey = worker.area
            if (areaKey === '3') {
              areaKey = '4'
            }

            if (week[areaKey] && week.status !== '3') {
              let entries = week[areaKey]

              // Si el área es 5, accedemos al nodo "vendedores"
              if (areaKey === '5' && entries.vendedores) {
                entries = entries.vendedores
              }

              // Buscar el salario neto
              const salarioNeto = buscarSalarioNeto(entries, worker.id)

              // Suma el salario neto a los acumuladores correspondientes
              if (areaKey === '3' || areaKey === '4')
                semana.distribPr += salarioNeto // Distribución (Preconsa)
              if (areaKey === '5')
                semana.ventasPr +=
                  salarioNeto *
                  (getComisionsLogisticWorker(worker.id, 1, 'preconsa') / 100) // Ventas (Preconsa)
              if (areaKey === '6')
                semana.adminPr +=
                  salarioNeto *
                  (getComisionsLogisticWorker(worker.id, 1, 'preconsa') / 100) // Administrativo (Preconsa)
              if (areaKey === '5')
                semana.ventasJOW +=
                  salarioNeto *
                  (getComisionsLogisticWorker(worker.id, 1, 'odilon') / 100) // Ventas (JO)
              if (areaKey === '6')
                semana.adminJOW +=
                  salarioNeto *
                  (getComisionsLogisticWorker(worker.id, 1, 'odilon') / 100) // Administrativo (JO)

              if (salarioNeto !== null) {
                doc.text(
                  xPosition + 280,
                  yPosition,
                  `${getComisionsLogisticWorker(worker.id, 1, 'odilon')}% - $${(
                    salarioNeto *
                    (getComisionsLogisticWorker(worker.id, 1, 'odilon') / 100)
                  ).toFixed(2)}`,
                  { align: 'right' }
                )

                doc.text(
                  xPosition + 400,
                  yPosition,
                  `${getComisionsLogisticWorker(
                    worker.id,
                    1,
                    'preconsa'
                  )}% - $${(
                    salarioNeto *
                    (getComisionsLogisticWorker(worker.id, 1, 'preconsa') / 100)
                  ).toFixed(2)}`,
                  { align: 'right' }
                )

                doc.text(
                  xPosition + 505,
                  yPosition,
                  `100% - $${(salarioNeto * 1).toFixed(2)}`,
                  { align: 'right' }
                )
              }
            } else {
              console.warn(
                `No se encontró el área clave: ${worker.area} en week.`
              )
            }
          } else if (
            String(worker.area) === '1' ||
            String(worker.area) === '2'
          ) {
            const areaKey = worker.area

            if (week[areaKey]) {
              // Calcular el salario total
              const salarioTotal =
                calcularSalarioTotalEnDias(week[areaKey], worker.id) +
                getRetencionesById(worker.id)

              if (areaKey === '1' || areaKey === '2')
                semana.fabrPr += salarioTotal // Fabricación (Preconsa)

              if (salarioTotal > 0) {
                doc.text(
                  xPosition + 280,
                  yPosition,
                  `${getComisionsLogisticWorker(worker.id, 1, 'odilon')}% - $${(
                    salarioTotal *
                    (getComisionsLogisticWorker(worker.id, 1, 'odilon') / 100)
                  ).toFixed(2)}`,
                  { align: 'right' }
                )

                doc.text(
                  xPosition + 400,
                  yPosition,
                  `${getComisionsLogisticWorker(
                    worker.id,
                    1,
                    'preconsa'
                  )}% - $${(
                    salarioTotal *
                    (getComisionsLogisticWorker(worker.id, 1, 'preconsa') / 100)
                  ).toFixed(2)}`,
                  { align: 'right' }
                )

                doc.text(
                  xPosition + 505,
                  yPosition,
                  `100% - $${salarioTotal.toFixed(2)}`,
                  { align: 'right' }
                )
              }
            }
          }
          yPosition += 20
        }
      })

      semanas.push(semana)

      if (counter === data.length) {
        doc.addPage()
        addHeader() // Nuevo encabezado para el resumen
        doc.setFontSize('12')
        let y = 120,
          countWeek = 1
        doc.text(60, 100, 'Resumen Final por Área:')
        doc.text(70, y, '• Preconsa')
        doc.text(
          160,
          y,
          'Administrativo              Ventas                 Distribución               Fabricación'
        )

        semanas.forEach((item) => {
          doc.text(
            80,
            y + 20,
            `Semana ${countWeek}          $                           $                              $                                $`
          )
          doc.text(230, y + 20, item.adminPr.toFixed(2), { align: 'right' })
          doc.text(330, y + 20, item.ventasPr.toFixed(2), { align: 'right' })
          doc.text(440, y + 20, item.distribPr.toFixed(2), { align: 'right' })
          doc.text(550, y + 20, item.fabrPr.toFixed(2), { align: 'right' })

          tAdmin += item.adminPr
          tVentas += item.ventasPr
          tDistrib += item.distribPr
          tFabr += item.fabrPr

          y += 20
          countWeek += 1
        })
        doc.line(145, y + 5, 570, y + 5)
        y += 20
        doc.text(135, y, 'Total', { align: 'right' })
        doc.text(
          81,
          y,
          `                          $                           $                              $                                $`
        )
        doc.text(230, y, tAdmin.toFixed(2), { align: 'right' })
        doc.text(330, y, tVentas.toFixed(2), { align: 'right' })
        doc.text(440, y, tDistrib.toFixed(2), { align: 'right' })
        doc.text(550, y, tFabr.toFixed(2), { align: 'right' })

        doc.text(70, y + 30, '• José Odilón')
        doc.text(160, y + 30, 'Administrativo              Ventas')
        countWeek = 1
        semanas.forEach((item) => {
          doc.text(
            80,
            y + 50,
            `Semana ${countWeek}          $                           $`
          )
          doc.text(230, y + 50, item.adminJOW.toFixed(2), { align: 'right' })
          doc.text(330, y + 50, item.ventasJOW.toFixed(2), { align: 'right' })

          tAdminJO += item.adminJOW
          tVentasJO += item.ventasJOW

          y += 20
          countWeek += 1
        })
        doc.line(145, y + 35, 570, y + 35)
        doc.text(135, y + 50, 'Total', { align: 'right' })
        doc.text(
          81,
          y + 50,
          `                          $                           $`
        )
        doc.text(230, y + 50, tAdminJO.toFixed(2), { align: 'right' })
        doc.text(330, y + 50, tVentasJO.toFixed(2), { align: 'right' })
      } else {
        console.log('Error:', counter, data.length)
      }

      doc.addPage()
    }
    counter += 1
  })

  const totalPages = doc.getNumberOfPages() // Obtén el número total de páginas

  if (totalPages > 1) {
    doc.deletePage(totalPages) // Elimina la última página
  }

  doc.autoPrint()
  doc.output('dataurlnewwindow', {
    filename: `Reporte del mes ${mes.toLocaleString('es-ES', {
      month: 'long',
    })}/${mes.getFullYear()}.pdf`,
  })
}
