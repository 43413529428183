export const unidades = [
  { id: 'Kenworth 18', title: 'Kenworth 18 (Tortón)' },
  { id: 'Kenworth 10', title: 'Kenworth 10' },
  { id: 'International', title: 'International' },
  { id: 'Camión Ford', title: 'Camión Ford' },
  { id: 'Camioneta Ford', title: 'Camioneta Ford' },
  { id: 'Nissan 2014', title: 'Nissan 2014' },
  { id: 'Recogen (Camioneta)', title: 'Recogen en Fabrica (Camioneta)' },
  { id: 'Recogen (Camion)', title: 'Recogen en Fabrica (Camion)' },
  { id: 'Recogen (Torton)', title: 'Recogen en Fabrica (Torton)' },
]
