import { useState, useLayoutEffect } from 'react'
import { onValue, ref, getDatabase, set } from 'firebase/database'
import { database } from '../configs/firebase'

export const costosValues = {
  hhChofer: '',
  hhAyudante: '',
  infonavit: '',
  imss: '',
  comKenworth18Ayudante: '',
  comKenworth10Ayudante: '',
  com10tonAyudante: '',
  com3tonAyudante: '',
  comKenworth18: '',
  comKenworth10: '',
  com10ton: '',
  com3ton: '',
  com1ton: '',
  dKenworth18: '',
  dKenworth10: '',
  dInter: '',
  dFord: '',
  gFord: '',
  gNissan: '',
  neuKen18: '',
  neuKen10: '',
  neu10ton: '',
  neu3ton: '',
  neu1ton: '',
  diesel: '',
  gasolina: '',
  camino: '',
  manInter: '',
  manFord: '',
  manFord2014: '',
  manKenworth18D1: '',
  manKenworth10D1: '',
  manKenworth18D2: '',
  manKenworth10D2: '',
  manKenworth18D3: '',
  manKenworth10D3: '',
  manNissan: '',
  refrendo: '',
  utilidad: '',
  utilidadAjustada: '',
  margen: '',
}

export const useCost = () => {
  const [cost, setCost] = useState([])

  useLayoutEffect(() => {
    onValue(
      ref(database, 'costos/'),
      (snapshot) => {
        const costList = []
        snapshot.forEach((item) => {
          const costItem = {
            id: item.key,
            concepto: item.val().concepto,
            costoAct: item.val().costoAct,
            tipo: item.val().tipo,
            valor: item.val().valor,
          }
          costList.push(costItem)

          switch (item.val().concepto) {
            case 'Camino Accidentado':
              costosValues.camino = item.val().costoAct
              break
            case 'Comision Camion Ayudante':
              costosValues.com10tonAyudante = item.val().costoAct
              break
            case 'Comision Camioneta Doble Ayudante':
              costosValues.com3tonAyudante = item.val().costoAct
              break
            case 'Comision Kenworth 18 Ayudante':
              costosValues.comKenworth18Ayudante = item.val().costoAct
              break
            case 'Comision Kenworth 10 Ayudante':
              costosValues.comKenworth10Ayudante = item.val().costoAct
              break
            case 'Comision Camion Chofer':
              costosValues.com10ton = item.val().costoAct
              break
            case 'Comision Camioneta Doble Chofer':
              costosValues.com3ton = item.val().costoAct
              break
            case 'Comision Kenworth 18 Chofer':
              costosValues.comKenworth18 = item.val().costoAct
              break
            case 'Comision Kenworth 10 Chofer':
              costosValues.comKenworth10 = item.val().costoAct
              break
            case 'Comision Nissan Chofer':
              costosValues.com1ton = item.val().costoAct
              break
            case 'Diesel':
              costosValues.diesel = item.val().costoAct
              break
            case 'Diesel Ford':
              costosValues.dFord = item.val().valor
              break
            case 'Diesel Inter':
              costosValues.dInter = item.val().valor
              break
            case 'Diesel Kenworth 10':
              costosValues.dKenworth10 = item.val().valor
              break
            case 'Diesel Kenworth 18':
              costosValues.dKenworth18 = item.val().valor
              break
            case 'Gasolina':
              costosValues.gasolina = item.val().costoAct
              break
            case 'Gasolina Ford 2014':
              costosValues.gFord = item.val().valor
              break
            case 'Gasolina Nissan':
              costosValues.gNissan = item.val().valor
              break
            case 'Hora Hombre Ayudante':
              costosValues.hhAyudante = item.val().costoAct
              break
            case 'Hora Hombre Chofer':
              costosValues.hhChofer = item.val().costoAct
              break
            case 'Infonavit':
              costosValues.infonavit = item.val().costoAct
              break
            case 'Mantenimiento Ford':
              costosValues.manFord = item.val().costoAct
              break
            case 'Mantenimiento Ford 2014':
              costosValues.manFord2014 = item.val().costoAct
              break
            case 'Mantenimiento Inter':
              costosValues.manInter = item.val().costoAct
              break
            case 'Mantenimiento Kenworth 10 Distancia 1':
              costosValues.manKenworth10D1 = item.val().costoAct
              break
            case 'Mantenimiento Kenworth 10 Distancia 2':
              costosValues.manKenworth10D2 = item.val().costoAct
              break
            case 'Mantenimiento Kenworth 10 Distancia 3':
              costosValues.manKenworth10D3 = item.val().costoAct
              break
            case 'Mantenimiento Kenworth 18 Distancia 1':
              costosValues.manKenworth18D1 = item.val().costoAct
              break
            case 'Mantenimiento Kenworth 18 Distancia 2':
              costosValues.manKenworth18D2 = item.val().costoAct
              break
            case 'Mantenimiento Kenworth 18 Distancia 3':
              costosValues.manKenworth18D3 = item.val().costoAct
              break
            case 'Mantenimiento Nissan':
              costosValues.manNissan = item.val().costoAct
              break
            case 'Neumaticos Camion':
              costosValues.neu10ton = item.val().costoAct
              break
            case 'Neumaticos Camioneta Doble':
              costosValues.neu3ton = item.val().costoAct
              break
            case 'Neumaticos Nissan':
              costosValues.neu1ton = item.val().costoAct
              break
            case 'Neumaticos Kenworth 10':
              costosValues.neuKen10 = item.val().costoAct
              break
            case 'Neumaticos Kenworth 18':
              costosValues.neuKen18 = item.val().costoAct
              break
            case 'Refrendo':
              costosValues.refrendo = item.val().costoAct
              break
            case 'Seguro Social':
              costosValues.imss = item.val().costoAct
              break
            case 'Utilidad':
              costosValues.utilidad = item.val().valor
              break
            case 'Utilidad Ajustada':
              costosValues.utilidadAjustada = item.val().valor
              break
            case 'Margen':
              costosValues.margen = item.val().costoAct
              break
            default:
              console.log('error')
          }
        })
        setCost(costList)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])
  return [cost]
}

export const getCost = () => {
  try {
    //const costList = []
    onValue(ref(database, 'costos/'), (snapshot) => {
      //const costList = []
      snapshot.forEach((item) => {
        /*const costItem = {
          id: item.key,
          concepto: item.val().concepto,
          costoAct: item.val().costoAct,
          tipo: item.val().tipo,
          valor: item.val().valor,
        }
        costList.push(costItem)*/

        switch (item.val().concepto) {
          case 'Camino Accidentado':
            costosValues.camino = item.val().costoAct
            break
          case 'Comision Camion Ayudante':
            costosValues.com10tonAyudante = item.val().costoAct
            break
          case 'Comision Camioneta Doble Ayudante':
            costosValues.com3tonAyudante = item.val().costoAct
            break
          case 'Comision Kenworth 18 Ayudante':
            costosValues.comKenworth18Ayudante = item.val().costoAct
            break
          case 'Comision Kenworth 10 Ayudante':
            costosValues.comKenworth10Ayudante = item.val().costoAct
            break
          case 'Comision Camion Chofer':
            costosValues.com10ton = item.val().costoAct
            break
          case 'Comision Camioneta Doble Chofer':
            costosValues.com3ton = item.val().costoAct
            break
          case 'Comision Kenworth 18 Chofer':
            costosValues.comKenworth18 = item.val().costoAct
            break
          case 'Comision Kenworth 10 Chofer':
            costosValues.comKenworth10 = item.val().costoAct
            break
          case 'Comision Nissan Chofer':
            costosValues.com1ton = item.val().costoAct
            break
          case 'Diesel':
            costosValues.diesel = item.val().costoAct
            break
          case 'Diesel Ford':
            costosValues.dFord = item.val().valor
            break
          case 'Diesel Inter':
            costosValues.dInter = item.val().valor
            break
          case 'Diesel Kenworth 10':
            costosValues.dKenworth10 = item.val().valor
            break
          case 'Diesel Kenworth 18':
            costosValues.dKenworth18 = item.val().valor
            break
          case 'Gasolina':
            costosValues.gasolina = item.val().costoAct
            break
          case 'Gasolina Ford 2014':
            costosValues.gFord = item.val().valor
            break
          case 'Gasolina Nissan':
            costosValues.gNissan = item.val().valor
            break
          case 'Hora Hombre Ayudante':
            costosValues.hhAyudante = item.val().costoAct
            break
          case 'Hora Hombre Chofer':
            costosValues.hhChofer = item.val().costoAct
            break
          case 'Infonavit':
            costosValues.infonavit = item.val().costoAct
            break
          case 'Mantenimiento Ford':
            costosValues.manFord = item.val().costoAct
            break
          case 'Mantenimiento Ford 2014':
            costosValues.manFord2014 = item.val().costoAct
            break
          case 'Mantenimiento Inter':
            costosValues.manInter = item.val().costoAct
            break
          case 'Mantenimiento Kenworth 10 Distancia 1':
            costosValues.manKenworth10D1 = item.val().costoAct
            break
          case 'Mantenimiento Kenworth 10 Distancia 2':
            costosValues.manKenworth10D2 = item.val().costoAct
            break
          case 'Mantenimiento Kenworth 10 Distancia 3':
            costosValues.manKenworth10D3 = item.val().costoAct
            break
          case 'Mantenimiento Kenworth 18 Distancia 1':
            costosValues.manKenworth18D1 = item.val().costoAct
            break
          case 'Mantenimiento Kenworth 18 Distancia 2':
            costosValues.manKenworth18D2 = item.val().costoAct
            break
          case 'Mantenimiento Kenworth 18 Distancia 3':
            costosValues.manKenworth18D3 = item.val().costoAct
            break
          case 'Mantenimiento Nissan':
            costosValues.manNissan = item.val().costoAct
            break
          case 'Neumaticos Camion':
            costosValues.neu10ton = item.val().costoAct
            break
          case 'Neumaticos Camioneta Doble':
            costosValues.neu3ton = item.val().costoAct
            break
          case 'Neumaticos Nissan':
            costosValues.neu1ton = item.val().costoAct
            break
          case 'Neumaticos Kenworth 10':
            costosValues.neuKen10 = item.val().costoAct
            break
          case 'Neumaticos Kenworth 18':
            costosValues.neuKen18 = item.val().costoAct
            break
          case 'Refrendo':
            costosValues.refrendo = item.val().costoAct
            break
          case 'Seguro Social':
            costosValues.imss = item.val().costoAct
            break
          case 'Utilidad':
            costosValues.utilidad = item.val().valor
            break
          case 'Utilidad Ajustada':
            costosValues.utilidadAjustada = item.val().valor
            break
          case 'Margen':
            costosValues.margen = item.val().costoAct
            break
          default:
            console.log('error')
        }
      })
    })
  } catch (error) {}
}

export const updateCost = (id, concepto, costoAct, tipo, valor) => {
  const db = getDatabase()

  set(ref(db, `costos/${id}`), {
    concepto: concepto,
    costoAct: costoAct,
    tipo: tipo,
    valor: valor,
  })
}
